@use "sass:math";

@import 'variables';

$active-background: darken($btn-bg, 10%);
$active-border: darken($btn-border, 12%);

.rbc-toolbar {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  flex-wrap: unset !important;

  .rbc-toolbar-label {
    width: 100%;
    padding: 0 10px;
    text-align: center;
  }

  & button {
    color: $btn-color !important;
    display: inline-block !important;
    margin: 0 !important;
    text-align: center !important;
    vertical-align: middle !important;
    background: $primary !important;
    border: 1px solid $btn-border !important;
    padding: .375rem 1rem !important;
    border-radius: $border-radius !important;
    line-height: normal !important;
    white-space: nowrap !important;
    font-weight: bold;
    text-transform: capitalize;
    font-size: math.div($font-size-sm, 1.1);

    &:active,
    &.rbc-active {
      background-image: none !important;
      box-shadow: inset 0 3px 5px rgba(0,0,0,.125) !important;
      background-color: $active-background !important;
      border-color: $active-border !important;

      &:hover,
      &:focus {
        color: $btn-color !important;
        background-color: darken($btn-bg, 17%) !important;
        border-color: darken($btn-border, 25%) !important;
      }
    }

    &:focus {
      color: $btn-color !important;
      background-color: $active-background !important;
      border-color: $active-border !important;
    }

    &:hover {
      color: $btn-color !important;
      background-color: $active-background !important;
      border-color: $active-border !important;
    }
  }
}

.rbc-btn-group {
  display: inline-block;
  white-space: nowrap;

  > button:first-child:not(:last-child) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  > button:last-child:not(:first-child) {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rbc-rtl & > button:first-child:not(:last-child) {
    border-radius: 4px;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .rbc-rtl & > button:last-child:not(:first-child) {
    border-radius: 4px;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  > button:not(:first-child):not(:last-child) {
    border-radius: 0 !important;
  }

  button + button {
    margin-left: -1px;
  }

  .rbc-rtl & button + button {
    margin-left: 0;
    margin-right: -1px;
  }

  & + &,
  & + button {
    margin-left: 10px;
  }
}
