.delete-btn{
    padding:3px 2px;
    margin:2;
    cursor:pointer
}
.delete-btn:hover{
   
  color: red !important;
}

.rbc-time-header 
{
  margin-left: 69px !important;
}
.rbc-header{width: 70px;}

.rbc-day-slot .rbc-event{
  height: 40px !important;
  background-color: #D5D8AC;
  color:#fff;
  font-size: medium;
  border-left-width:5px !important;
  border-left-color: orange !important;
  
}

.rbc-day-slot .rbc-event .rbc-event-label
{
  color: black;
  padding-left: 10px;
  margin-top: 18px;
  margin-left: 18px;
  font-size: 10px;
}

.rbc-day-slot .rbc-event .rbc-event-content 
{

  padding-left: 10px;
  margin-top: -37px;
}
.rbc-day-slot .rbc-event .rbc-event-content  div
{
  color: black;
  /* background-color: #ffffff !important; */
  font-size: 14px; 
}

.consultantDoctors{
  display: none;
}