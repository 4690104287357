@page {
  margin: 10mm;
  size: 210mm 297mm;
}

.report-page {
  background-color: white;
  box-sizing: border-box;
  width: 210mm;
  /* height: 297mm; */
  border: 1px solid black;
  page-break-after: always;
}

.idcard-page {
  background-color: white;
  box-sizing: border-box;
  width: 210mm;
  height: 297mm;

  page-break-after: always;
}
@media print {
  .report-page {
    width: 100%;
    height: 100vh;
    margin: 0;
  }

  .idcard-page {
    width: 100%;
    height: 100vh;
    margin: 20px;
  }
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 1162px;
    margin: 1.75rem auto;
  }
}
.border {
  border: 1px solid red;
}
.padd-20 {
  padding: 20px;
}
.padd-10 {
  padding: 10px;
}
.clearfix {
  clear: both;
}
.line {
  height: 1.5px;
  background-color: black;
}
.info-label {
  min-width: 250px;
}

.report-label-key {
  min-width: 100px;
  color: black;
  font-size: 12px;
  font-weight: bold;
}
.report-label {
  min-width: 100px;
  color: black;
  font-size: 12px;
}

.id-card {
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  width: 300px;
}

.id_label {
  float: left;
  width: 40%;
  text-align: left;

  padding-left: 10px;
}
.id_label a {
  font-weight: 400;
  width: 100px;
  display: block;
}
.id-photo {
  height: 150px;
  width: 150px;
  border-radius: 100%;
  border: 3px solid white;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
}
.id-qrcode {
  height: 100px;
  width: 100px;
  background-color: white;

  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75);
}
.id-separator {
  float: left;
}
.id_value {
  float: right;
  text-align: left;
  padding-left: 10px;
  width: 57%;
}

.capitalize {
  text-transform: capitalize;
}

.logoImage {
   width: 160px;
  /*max-height: 100px; */
}
.logoImage img {
  width: auto;
  height: 80px;
  object-fit: contain;
  max-width: 100%;
}

.form-header {
  min-height: 80px;
}
.hospital_no {
  display: inline;
  float: right;
}

.todayDate {
  display: inline;
}
.imageContainer {
  width: 100%;
}

.hospitalName {
  display: inline;
}

.hospitalAddress {
  display: inline;
  float: right;
}

.container {
  border: 1px solid rgba(32, 39, 140, 0.125);
}

table.table-bordered-custom {
  border: 1px solid black;
}

.all-label {
  font-weight: bold;
  padding: 5px;
}
.table-full-width {
  width: 100%;
}
.table-label {
  width: 20%;
}
.table-content-three-rows {
  width: 40%;
}
.label-center-align {
  text-align: center;
}
.formHeading {
  display: inline;
  margin: auto;
  margin-left: 229px;
  color: blue;
}

.full-width {
  width: 100%;
}

.selecteServicesClass {
  position: fixed;
  left: 780px;
  top: 40px;
  width: 400px;

  overflow: scroll;
}

.serviceListClass {
  position: fixed;
  left: 40px;
  width: 700px;
  max-height: 500px;
  overflow: scroll;
}

.allServicePackagesBody {
  background-color: transparent !important;
  width: 1300px;
}

.allServicePackages {
  width: 1300px;
  margin-left: 50px;
}

.fiftyWidth {
  width: 50px;
}

.seventyfiveWidth {
  width: 75px;
}

.hundredWidth {
  width: 100px;
}

.twohundredWidth {
  width: 200px;
}

.float-right-margintenpx {
  width: 100%;
  margin: 10px;
  float: right;
}

.floatright {
  float: right;
}

.marginrighttenpx {
  margin-right: 10px;
}
.margin-left-tenpx {
  margin-left: 10px;
}

.textAlignCenter {
  text-align: center;
}

.threehundredWidth {
  width: 300px;
}
