.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto
{
    padding-left: 5px;
    padding-right: 5px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

.react-datepicker__input-container .form-control
{height: 30px !important;
padding: 0px !important}


.react-datepicker-popper{
  transform: translate3d(39px, 30px, 0px) !important;
}
.mandatory{
    color: red !important;
    font-size: 16px;
    font-weight: bold;
    line-height: 10px
}
.rdp-addon {
    margin-right: -27px !important
}

.customer-detail{
    background-color: rgb(255, 255, 255);
  font-size: 12px;
  /* padding: 10px; */
  border: 1px solid rgb(224, 224, 224);
  border-radius: 5px;
  overflow: hidden;
  }
  .customer-detail-heading{
  text-align: center;
  font-size: 16px;
  padding: 2px 0;
  border-bottom: 1px solid rgb(224, 224, 224);
  
  background-color: rgb(243, 243, 243);
  
  }
  .customer-detail-footer{
    padding: 2px;
  }
  .customer-detail-footer button{
    margin-right: 2px;
  }
  .customer-detail .row{
    margin: 0px !important;
    border-bottom: 1px solid rgb(224, 224, 224);
    }
    .customer-detail .row:last-child{
  
      border-bottom: 0px solid rgb(224, 224, 224);
      }
  .customer-detail .heading{
  font-weight: bold;
  background-color: rgb(247, 247, 247);
  border-right: 1px solid rgb(224, 224, 224);
  }
  .customer-detail .text{
    /* font-weight: bold; */
    padding: 2px 0px;
    }
    .customer-detail .row:last-child .text{
      /* font-weight: bold; */
      padding-bottom: 8px;
      }
      .customer-detail .badge {
       
        font-size: 90%;
      }
  

      .css-bg1rzq-control{
        min-height: 30px !important;
      }

      .css-1szy77t-control:hover {
        border-color: #2684FF;
        height: 28px !important;
    }

 
      .css-1hwfws3{
        height:30px
      }
      .css-1wy0on6{
        height:30px
      }

      .react-datepicker__input-container .form-control{
        font-size: 14px;
        padding-left: 10px;
        text-align: center;
      }

      .react-datepicker__input-container .removeUnwantedClass{
        font-size: 1rem !important;
        height: calc(1.5em + 0.75rem + 2px) !important;
        padding: 0.375rem 0.75rem !important;
        text-align:left !important;
      }

    .hPLFLc{
        -webkit-box-shadow: 1px 5px 9px -4px #000000; 
        box-shadow: 1px 5px 9px -4px #000000;
      }

      .shadow{ -webkit-box-shadow: 1px 5px 9px -4px #000000; 
        box-shadow: 1px 5px 9px -4px #000000;}

        .icon-style{
          font-size: 24px;
            margin-top: 3px;
            padding-top:8px;}
            .css-kj6f9i-menu
            {
              z-index: 10000 !important;
            }

            /* FOR datatable search bar */
            .hPLFLc{display: block;
            padding: 0px;} 

            .accordian:hover{text-decoration: none;}
            .accordian:active{text-decoration: none;}