
.overlay {
    background: black;
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.5;
    z-index: 1000;
}
#loading-img{
    position: absolute;
    top: 50%;
    left: 50%;
}