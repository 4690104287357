// Bar Css
.add-new-form-btn {
  background-color: #4da8da;
  border: 1px solid #4da8da;
  color: #fff;
  width: 100%;
  height: 38px;
  margin-top: 20px;
  &:hover {
    background-color: #fff;
    color: #4da8da;
    border: 1px solid #4da8da;
  }
}

.modal-content {
  border-radius: 20px;
}

// IPD FORM
.booked-box {
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 4px;
  margin: 5px;
}
.available-box {
  width: 20px;
  height: 20px;
  background-color: #4ce600;
  border-radius: 4px;
  margin: 5px;
}
.reserved-box {
  width: 20px;
  height: 20px;
  background-color: #4da8da;
  border-radius: 4px;
  margin: 5px;
}
.box-divider {
  border-bottom: 1px solid lightgrey;
  margin-top: 20px;
  margin-bottom: 20px;
}

//beds grid made by sushil

.beds-grid {
  margin-left: 10px;
  margin-top: 0px;
  display: flex;
  justify-content: center;
  background: white;
  border: 1px;
}

//drawgrid grid element by sushil
.drawGrid td {
  // width: 55px;
  // height: 50px;
  padding: 6px 6px;
  border: 1px solid orange;
  text-align: center;
  float: left;
  margin: 5px;
}

.drawGrid td:hover {
  opacity: 0.7;
  cursor: pointer;
}

.reserved {
  // background: orange;
  background: #4da8da;
  color: #fff;
  display: inline-block;
}

//reserved seat style by sushil
.reserved::after {
  content: " (RESERVED)";
  display: block;
  width: 100%;
}

.available {
  color: #333;
  background: #4ce600;
  display: inline-block;
}

.occupied {
  color: #333;
  background: red;
  display: inline-block;
  cursor: not-allowed !important;
}

.occupied::after {
  content: " (OCCUPIED)";
  display: block;
  width: 100%;
}

//available : after seat style by sushil
.available::after {
  content: " (AVAILABLE)";
  display: block;
  width: 100%;
}

//available style by sushil
// .available {
//   color: #333;
// }

//container-class for
.container-drawgrid {
  // width: 600px;
  margin: auto;
}

// Form Css
.title-heading-bar {
  background-color: #f2f2f2;
  padding: 5px;
  margin-bottom: 20px;
  margin-left: -20px;
  margin-right: -20px;
}

.title-text {
  margin-left: 20px;
  margin-top: 5px;
}
.edit-address-btn {
  display: block;
  float: right;
  margin-top: 10px;
  margin-bottom: 20px;
  // margin-left: auto;
  // margin-right: auto;
  background-color: #4da8da;
  border: 1px solid #4da8da;
  width: 235px;
  &:hover {
    background-color: #fff;
    color: #4da8da;
    border: 1px solid #4da8da;
  }
}
.input-label-component {
  align-items: center;
}

.label-star {
  color: red;
}

.add-form-exit-btn {
  background-color: red;
  border: 1px solid red;
  width: 150px;
  margin: 20px;
  &:hover {
    background-color: #fff;
    color: red;
    border: 1px solid red;
  }
}

.add-form-clear-btn {
  background-color: grey;
  border: 1px solid grey;
  width: 150px;
  margin: 20px;
  &:hover {
    background-color: #fff;
    color: grey;
    border: 1px solid grey;
  }
}

.add-form-save-btn {
  background-color: #4da8da;
  border: 1px solid #4da8da;
  width: 150px;
  margin: 20px;
  &:hover {
    background-color: #fff;
    color: #4da8da;
    border: 1px solid #4da8da;
  }
}

input.valid {
  border-left: 7px solid #4ce600;
}

input.invalid {
  border-left: 7px solid red;
  border-color: red;
}

.invalid-feedback {
  display: block;
  text-align: right;
  // color: red;
  // font-size: 13px;
}

// Table css

// Table css
.table-header-sr-no {
  // text-align: left;
  // background-color: #4da8da;
  color: black !important;
}
.table-header-others {
  text-align: left;
  color: black !important;
}
.table-sr-no {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.delete-icon {
  margin: 5px;
  width: 30px;
  height: 30px;
}

// check box Css
.check-box-flex {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}
.check-box-label {
  color: black;
}
.check-box-input {
  outline: 1px solid #4ce600;
  width: 20px;
  height: 20px;
}
.input-field-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// radio Button Css
.radio-btn-flex {
  display: flex;
}
.radio-btn-group {
  margin: 10px;
}
.radio-btn {
  width: 20px;
  height: 20px;
}
.radio-btn-label {
  margin: 5px;
}

// input field
.product-box-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product-input-field {
  width: 10%;
}
.product-small-input-field {
  height: 30px;
}

.disabled-btn {
  pointer-events: none;
}

.pageTitleAction {
  display: none;
}

.my-appear {
  opacity: 0;
}

.my-appear-active {
  opacity: 1;
  transition: opacity 2000ms;
}

.my-appear-done {
  opacity: 1;
}
// Responsive Css
@media screen and (min-width: 360px) and (max-width: 812px) {
}
