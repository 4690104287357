.custom-icon {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  /* padding: 15px; */
  margin-top: -18px !important;
  margin-left: -10px;
  margin-bottom: -15px;
  border-radius: 50%;
  background-color: #eef9f6;
  /* border: 2px solid white; */
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.75), lightgreen 0px 0px 3px inset;
  transition: all 0.2s ease-in-out !important;
  /* box-shadow: 10px -7px 15px darkgray, lightgreen 0px 0px 3px inset; */
}

.metismenu-link:hover .custom-icon {
  transform: scale(1.5);
}
.icon-menu-home {
  background-image: url("https://assets.aquahis.com/assets/Icons/home.png");
}

.icon-menu-organization {
  background-image: url("https://assets.aquahis.com/assets/Icons/corporate.png");
}

.icon-menu-account {
  background-image: url("https://assets.aquahis.com/assets/Icons/appointment.png");
}

.icon-menu-registration {
  background-image: url("https://assets.aquahis.com/assets/Icons/registration.png");
}

.icon-menu-home {
  background-image: url("https://assets.aquahis.com/assets/Icons/home.png");
}

.icon-menu-opd {
  background-image: url("https://assets.aquahis.com/assets/Icons/out-patient.png");
}

.icon-menu-ipd {
  background-image: url("https://assets.aquahis.com/assets/Icons/in-patient-old.png");
}

.icon-menu-laboratory {
  background-image: url("https://assets.aquahis.com/assets/Icons/laboratory.png");
}

.icon-menu-ward {
  background-image: url("https://assets.aquahis.com/assets/Icons/ward.png");
}

.icon-menu-pharmacy {
  background-image: url("https://assets.aquahis.com/assets/Icons/pharmacy.png");
}

.icon-menu-home {
  background-image: url("https://assets.aquahis.com/assets/Icons/home.png");
}

.icon-menu-home {
  background-image: url("https://assets.aquahis.com/assets/Icons/home.png");
}

.icon-menu-home {
  background-image: url("https://assets.aquahis.com/assets/Icons/home.png");
}

.icon-menu-home {
  background-image: url("https://assets.aquahis.com/assets/Icons/home.png");
}
.vertical-nav-menu .metismenu-container i.metismenu-icon {
  opacity: 1;
}
/* .vertical-nav-menu .metismenu-container i.metismenu-state-icon, .vertical-nav-menu .metismenu-container i.metismenu-icon {
    height:40px;
    width:40px;
} */
.vertical-nav-menu .metismenu-item {
  margin: 0px;
  padding: 3px;
  position: relative;
}
.vertical-nav-menu .metismenu-item .metismenu-container .metismenu-item {
  margin: 0px;
  padding: 0px;
  position: relative;
}
