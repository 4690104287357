img.profileImg {
  width: 100%;
  max-width: 150px;
  height: 150px;
  border: 3px solid #545cd8;
  border-radius: 50%;
  padding: 5px;
  margin-bottom: 10px;
  box-shadow: 0px 0px 12px #000000;
}
img.idProof {
  width: 100%;
  height: 120px;
  overflow: hidden;
}
.profile-img-container {
  text-align: center;
}

.flag-select.rw-combobox.rw-widget {
  border: 1px solid #ced4da;
  width: 80%;
  padding: 0px;
  border-radius: 5px;
}
.forms-wizard-vertical.consultantMasterssteps ol.forms-wizard {
  width: 9%;
}
.forms-wizard-vertical.consultantMasterssteps ol.forms-wizard em {
  display: none;
}

.forms-wizard-vertical.consultantMasterssteps ol.forms-wizard em {
  display: none;
}

.forms-wizard-vertical.consultantMasterssteps ol.forms-wizard li {
  height: 100px;
  margin: 0px;
  border: 1px solid #eee;
  border-radius: 0px;
}

.forms-wizard-vertical.consultantMasterssteps
  ol.forms-wizard
  li.form-wizard-step-doing {
  border: 1px solid #545cd8;
}

.forms-wizard-vertical.consultantMasterssteps
  ol.forms-wizard
  li.form-wizard-step-doing
  span:after {
  content: "";
  display: block;
  position: absolute;
  border: 9px solid #545cd8;
  top: 45%;
  right: -10px;
  height: 16%;
  width: 16%;
  transform: rotate(45deg);
}

.uploadmodal {
  max-width: 800px !important;
}
.right-side {
  float: right;
  margin-top: 20px !important;
  margin-right: 15px !important;
}
.hmgroup {
  border: 1px solid #ccc;
  width: 425px;
  max-width: 100%;
  padding: 10px;
  text-align: center;
}

.servicetab button.btn-pill.btn-wide {
  background: none !important;
  color: #000 !important;
  border-radius: 0px !important;
  border: 0px;
  padding: 11px 35px;
  font-size: 16px;
  margin-bottom: -2px;
}
.servicetab button.btn-pill.btn-wide.active {
  border-bottom: 4px solid #545cd8 !important;
}
.servicetab {
  border-bottom: 1px solid #545cd8;
}
.servicetabcontent .tab-pane.active {
  background: #bdf0c3;
  padding: 16px 40px;
  color: #000;
  font-size: 16px;
}
.servicetabcontent .servicetabcontent1.tab-pane.active {
  background: #abd9fa;
  padding: 16px 40px;
  color: #000;
  font-size: 16px;
}

.bold {
  font-weight: bold;
}
.floatLeft {
  float: left;
}

.floatRight {
  float: right;
}

.marginTenPx {
  margin: 5px;
}

.custom-radio .custom-control-label::before {
  margin-top: 5px;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  margin-top: 5px;
}
.marginTopNegativeFour {
  margin-top: -4px;
}

.logoImage {
  display: inline-block;
}

.hospital_header {
  width: 78%;
  display: inline-block;
  //margin-left: 10%;
  text-align: center;
}

.formBillHeading {
  margin-left: 8%;
  display: inline-block;
}

.hospitalfootersign {
  float: right;
}

.bill_footer {
  border-top: 1px solid black;
  padding: 5px;
}

.display_block {
  display: block;
  width: 100%;
  text-align: right;
  margin-right: 23px;
}

.bottom-border {
  border-bottom: 1px solid black;
}

.container-table {
  border: 1px solid black !important;
}

.width-60 {
  width: 63%;
}
.width-20 {
  width: 19%;
}
.textaligncenter {
  text-align: center;
}

.displayinline {
  display: inline;
}
.bold {
  font-weight: bold;
}

.font9px {
  font-size: 9px;
}

.displayblock {
  display: block;
}

.displayinlineblock {
  display: inline-block;
}

.receipt-form tr {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  border-collapse: collapse;
}

.custom-modal-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  text-transform: uppercase;
  color: rgba(18, 21, 78, 0.7);
  font-weight: bold;
  font-size: 0.88rem;
}
.margininLabelTop {
  margin-top: 25px;
}

.font-size-10px {
  font-size: 10px;
}

.noborderinput tr td input {
  border: none;
  border-bottom: 1px solid grey;
}

.checkboxposition {
  margin-left: 3% !important;
  margin-top: 5% !important;
}

.centerAlignText {
  margin: auto;
  text-align: center;
  width: 100%;
}

.customizedFonts {
  font-size: 11px;
}

.custom-selects {
  font-size: 16px;
}

// login page css start

.logoWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  .clientLogo {
    img {
      width: auto;
      height: 75px;
    }
  }

  .dariaLogo {
    h4 {
      margin: 0;
    }
  }
}

// login page css end

/* Page Title css start */

.app-page-title {
  padding: 15px 30px 15px !important;
  margin-bottom: 10px !important;
}

.iconWrapper {
  background-color: #fff;
  border-radius: 50%;
  padding: 8px;

  &_image {
    height: 45px;
    width: auto;
  }
}

/* Page Title css end */

// shortcut css start

.header-megamenu {
  .nav-link {
    font-size: 18px;
  }
}

// shortcut css end

.customRadio_field {
  &:not(:last-child) {
    margin-right: 15px;
  }
}

.wardService {
  &_charges {
    .hmgroup {
      width: auto;
    }
    .custom-radio {
      text-align: left;
    }
  }
  &_types {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
  &_title {
    padding-bottom: 24px !important;
  }
}

.inwardServices {
  &_Btn {
    margin-top: 35px;
  }
}

.wardService {
  &_input {
    width: 192px;
  }
  &_inputShare{
    input{
      width: 98px;
    }
  }
}
.salesItem {
  &_table {
    max-height: 200px;
    overflow-y: auto;
  }
  &_tableHeading {
    font-size: 10px;
  }
}
.salesItem {
  &_table {
    max-height: 200px;
    overflow-y: auto;
  }
  &_tableHeading {
    font-size: 10px;
  }
}
.pharmaSales_Payment {
  margin-bottom: 15px;
  padding-bottom: 8px;
  border-bottom: 1px solid #ddd;
  font-weight: 500;
  font-size: 16px;
  color: #262626;
}
.cardWrapper {
  height: 100%;
  .cardHeader {
    height: auto;
    padding: 0.625rem 1.25rem;
  }
}

// print css
.patientDetails strong {
  // width: 150px;
  display: inline-block;
}
.patientDetails span {
  padding: 0 10px;
}

.borderBottom {
  border-bottom: 1.5px solid #000;
}
.borderTop {
  border-top: 1.5px solid #000;
}
.printTable {
  width: 100%;
}

.hospitalLogoWrapper {
  width: 160px;
}
.hospitalContentWrapper {
  width: 84%;
  text-align: center;
  line-height: 22px;
}
.hospitalLogo {
  // width: 120px;
  max-width: 100%;
  width: auto !important;
  height: 80px;
  object-fit: contain;
}
.hospitalName {
  display: block;
}

.totalBillWrapper {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  width: 50%;
}

.totalBillInfo {
  display: flex;
  align-items: center;
}
.totalBillInfo strong {
  flex-basis: 45%;
}
.totalBillInfo span {
  flex-basis: 10%;
  text-align: center;
}
.salesReturnBill {
  font-size: 11px;
  strong {
    width: 100px;
  }
}
.checkboxContainer {
  position: relative;
  margin: 0;
}

.customLink {
  text-decoration: underline !important;
  color: #0000ff !important;
}

.billConfig {
  &_wrapper {
  }
  &_header {
    height: auto;
    padding: 0.75rem 1.25rem;
  }
}

.labelFontSmall {
  label {
    font-size: 13px;
  }
}

.selectBoxWrapper {
  z-index: inherit;
}
.posTables {
  th {
    font-size: 13px;
  }
}

.posTable {
  &_sno {
    width: 50px;
  }
}

.topStatusHeading{
 font-size: 18px; 
 text-align: center;
 margin: 0;
 padding-bottom: 15px;

}
.modalReturnBill{
  max-width: 75%;
}
.checkboxBillingPage{
  transform: scale(1.5)
}


.disable_switchbtn{
  pointer-events: none;
  opacity: 0.6;
}

//page responsive css

.responsiveGrid {
  padding: 10px;

  // .card-body{
  header {
    & > .row:first-child {

      // padding: 0 !important;


      &>.col-md-4 {
        @media(min-width:768px) and (max-width:991px){
          flex: 0 0 60%;
          max-width: 60%;
        }

        @media(min-width: 992px) and (max-width:1250px) {
          flex: 0 0 50%;
          max-width: 50%;
        }

      }
      // .text-right{ 
      //   br{
      //     display: none;
      //   }
      // }

      div:nth-child(4) {
        // display: none;
      }
    }
  }

  // }
}

@media(min-width: 992px) and (max-width:1250px) {}

@media(max-width: 991px) {
  .app-page-title {
    .page-title-wrapper {
      display: flex;
      justify-content: space-between;
    }

    .page-title-heading {
      display: flex;
      margin: 0;

      h3 {
        margin-bottom: 0;
      }
    }

    .page-title-actions {
      margin: 15px 0 0;
    }
  }
}

.commonPrint{
  &_container{
    display: flex;
    align-items: center;
    padding: 10px 10px 10px;
  }

  &_left{
    width: 15%;
    margin-right: 15px;
  }
  &_logo{
      max-height: 96px;
      width: 100%;
  }
  &_content{
    width: 85%;
    text-align: center;
    h4{
      font-size: 20px;
      font-weight: 600;
    }
    p{
      margin-bottom: 0;
    }
  }
}
.currentStockWrapper{
  table{
    th, td{
      word-break: break-word;
      white-space: pre-wrap;
    }    
  }
}

.reactMultiSelect{
  .basic-multi-select{
    .css-1hwfws3, .css-1wy0on6{
      height: auto;
    }
    .css-1szy77t-control:hover{
      height: auto !important;
    }
  }
}

// OPIA consultant page

.consultantPdf{

  &_symptom{
    &:not(:last-child){
      margin-bottom: 8px;
    }
    strong{
      font-size: 12px;
      border-bottom: 1px solid #333;
      padding-bottom: 1px;
      display: inline-block;
      margin-bottom: 6px;
    }
    .listItems{
      span{
        text-transform: capitalize;
      }
      b{
        text-transform: capitalize;
        font-size: 13px;
        font-weight: 500;
      }
    }
    .tableContainer{
      margin-top: 8px !important;
    }
  }

  &_investigation{
    strong{
      font-size: 12px;
      border-bottom: 1px solid #333;
      padding-bottom: 1px;
      display: inline-block;
      margin-bottom: 6px;
    }
    ul{
      list-style-type: auto;
      padding-left: 14px;
      margin-bottom: 10px;
      li{
        font-size: 12px;
      }
    }
  }
}

// print pdf logo css

.printLogoWrapper{
  width: 20%;
  .printLogoImg{
    width: auto; 
    height: 50px; 
    max-width: 100%;
    object-fit: contain;
  }
}

// header logo

.navLogo{
  width:90px;
  img{
    width: auto;
    height: 50px;
    object-fit: contain;
    max-width: 100%;
  }
}

.underlinedText{
  span{
    font-size: 12px;
    border-bottom: 1px solid #333;
    padding: 0 0 0px;
    display: inline-block;
    margin-bottom: 6px;
  }
}

// medical history

.medicalHistory{
  position: relative !important;
  top: unset !important;
  .history{
    &_section{
  
    }
    &_rowWrapper{
      display: flex;
      // align-items: center;
    }
    &_inputGroup{
      input[type="number"]{
        background-color: #fff !important;
        border-radius: 4px 0px 0px 4px;
      }
      .input-group-append{ 
        height:32px;
        .input-group-text{
          font-size: 14px;
          background-color: #f3f3f3;
        }
      }
  
    }
  }
}

.stickyRightBox{
  position: sticky;
  top: 12%;
}

.vitalsDrawerWrapper{
  position: relative;
  background-color:#fff;
  margin-top: 60px;
  max-width: 100%;
  height: 90vh !important;
  padding: 15px 20px;
  // width: 100%; 
}

.customInputGroup{
  &_wrapper{
    .input-group-append{ 
      height:32px;
      .input-group-text{
        font-size: 14px;
        background-color: #f3f3f3;
        padding: 0.3rem 0.4rem;
        .subText{
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
  }
  &_input{
      background-color: #fff !important;
      border-radius: 4px 0px 0px 4px;
      border-left: 1px solid #ced4da !important;
      height: 32px;
      font-size: 14px;
      padding: 0.375rem 0.2rem;
  }
}

.vitalsTableWrapper{
  &.table-hover{
    tbody{
      tr{
        &:hover{
          background-color: rgba(0, 201, 13, 0.07);
        }
      }
    }
  }

  thead{
    background-color: #f5f5f5;
  }
  th, td{
    font-size: 13px;
  }
  th{
    width: 120px;
  }
}

// medical history Print

.medicalprintTable{
  &_wrapper{
    margin-top: 12px;
  }
  &_title{
    font-size: 12px;
    font-weight: bold;
    border-bottom: 1.5px solid #555;
    display: inline-block;
    text-transform: uppercase;
    margin-bottom: 0;
  }
  &_items{
    margin-bottom: 10px;
  }
}
.medicalHistoryPrint{
  margin-top: 10px !important;
  table{
    font-size: 12px !important;
    tr{
      td{
        text-transform: capitalize;
      }
    };
  }
}

.consultantDetails{
  border: 1px solid #ddd;
  padding: 5px;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  .consultantItems{
    display: flex;
    flex-direction: column;
  }
}
.ipdPrintStyle{
  text-align: left;
  margin-top: -12px;
  margin-bottom: 10px ;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.listFooterTotal{
  justify-content: flex-end !important;
  flex-direction: column;
  margin-bottom: 10px;
  .reportPdfList{
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 6px;
    .label{
      width: 90%;
      text-align: right;
      padding: 0 20px 0 0;
      font-size: 13px;
    }
    .value{
      width: 9%;
      padding: 0;
      font-size: 13px;
    }
  }
}

.mandatoryNotes{
  font-size: 13px;
  color: #666;
}

.subtestTable{
  tr{
    th{
      min-width: 55px;
    }
    td{
      input, textarea{
        width: 70px;
      }
    }
  }
}

.vitalsPrintWrapper{
  .vitalsPrintHeader, .vitalsPatientInfo, .vitalsPrintFooter{
    display: none;
  }
}

@media print{
  body{
    margin: 1.2em;
  }
  .vitalsPrintWrapper{
    border: 1px solid #444;
    padding: 10px;
    .vitalsPrintHeader, .vitalsPatientInfo, .vitalsPrintFooter{
      display: block;
    }
    .vitalsTableWrapper{
      margin-top: 1rem;
    }
  }
}

.printPdfStyle{
  // table{
    td{
      color: #000;
      font-size: 12px;
    }
  // }
}

// Pdf tableFoot New
.tableFoot{
  display: flex;
  justify-content:space-between;
  font-size: 12px;
  color: #000;
  margin-top: 8px;
  &Left{
    display: flex;
    align-items:center;
    width: 30%;
    p{
      margin-bottom: 0;
      padding-right: 10px;
    }
  }
  &Right{
    width: 70%;
    text-align: right;
    p{
      display: flex;
      justify-content: flex-end;
      margin-bottom: 0;
     &:not(:last-child){
      margin-bottom: 8px;
     }
     span{
      display: inline-flex;
      padding-right: 10px;
     }
     b{
      display: inline-flex;
      flex: 0 0 15%;
      text-align: left;
     } 
    }
  }
}

.itemListWrapper{
  .printHeader{
    display: none;
  }
}
@media print{
  .itemListWrapper{
    .printHeader{
      display: block;
    }
  }
}
.customPopOverStyle{
  max-height: 450px;
  overflow-y: scroll;
}

.customBillHeader{
  tr{
    display: flex;
    justify-content: space-between;
    div{
      display: flex
    }
    td{
      display: flex;
      align-items: center;
      &:nth-child(1){
        width: 70%;
      }
      &:nth-child(2){
        width: 250px;
      }
      label{
        margin: 0;
        display: block;
        padding: 0 6px 0 0 !important;
      }
      span{
        display: block;
      }
        
    }
    }
}

@media print{
  .customBillHeader{
    tr{
      td{
        &:nth-child(1){
          width: 60%;
        }
        &:nth-child(2){
          width: 40%;
        }
      }
      }
  }
}

.receiptForm{
  width: 100%;
  tr{
    border-bottom: 1px solid #000;
    font-size: 13px;

  }
}